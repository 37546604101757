import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import Header from "./Components/header";
import NavigationBar from "./Components/navbar";
import { useState, useEffect } from "react";


//async function getData(URL) {
//    const response = await fetch(URL);
//    const data = await response.json();
//    console.log(data);
//    return data;
//}


//setData([...data,getData(URL)]);// = getData(URL);

const App = () => {
  const today = new Date().toLocaleDateString();
  const URL = "testsite.be-well-clinic.com/API/";
  const [data,setData] = useState({});



  const getData = async () => {
    const response = await fetch(URL);
    const jsonData = await response.json();
    setData(jsonData);
  };

  useEffect(() => {
    getData();
  }, []);

console.log(JSON.stringify(data));

return (
    <div className="App">
      <Header Title="Open Appointments for" date={today} />
      <NavigationBar
        Title="Be Well Open Appointments are "
        appointments={ JSON.stringify(data) }
        //appointments = { "" }     
        />
    </div>
  );
};

export default App;
